import { debug } from "console";
import { OpenseaData, OpenseaDataExist } from "../Types/Collections/OpenSeaData";
import { WalletsStats } from "../Types/WalletsStats";
import {
  CASH_POSITION_COLLECTION,
  FLOOR_PRICES_COLLECTION,
  SHARES_COLLECTION,
  STATS_COLLECTION,
} from "../Utils/constants";



class MoralisRepository {
  Moralis: any;
  public init(Moralis: any) {
    this.Moralis = Moralis;
  }

  public async saveNftFloorPriceMany(array: OpenseaDataExist[], onSuccessInssert: any) {
    const tasks = array.map((data: OpenseaDataExist) => {
      return new Promise(async (resolve) => {
        const FloorPriceCollection = this.Moralis.Object.extend(
          FLOOR_PRICES_COLLECTION
        );
        const item = data.item;
        let fpObject;
        if (!data.isInsert) {
          const query = new this.Moralis.Query(FLOOR_PRICES_COLLECTION);
          query.equalTo("tokenAddress", item.tokenAddress);
          query.equalTo("slug", item.slug);
          fpObject = await query.first();
        } else {
          fpObject = new FloorPriceCollection();
        }

        if (fpObject === undefined) {
          console.error(`Problems with ${item.slug}, DB:${!data.isInsert}`);
          resolve([]);
        }

        this.copyProperties(item, fpObject);
        await fpObject.save().catch((e: any) => {
          console.error(`Problems with ${item.slug}`)
        });
        onSuccessInssert(item);
        resolve([]);
      });
    });

    return await Promise.all(tasks);
  };

  public async saveNftFloorPrice(data: OpenseaData) {
    const FloorPriceCollection = this.Moralis.Object.extend(
      FLOOR_PRICES_COLLECTION
    );
    const fpObject = new FloorPriceCollection();
    this.copyProperties(data, fpObject);
    return fpObject.save();
  };

  public async saveShareValue(value: number, date: Date) {
    const SharesCollection = this.Moralis.Object.extend(SHARES_COLLECTION);
    const shareObject = new SharesCollection();
    shareObject.set("value", value);
    return shareObject.save();
  };

  public async saveCashPositionValue(username: string, value: number, date: Date) {
    const CashPositionCollection = this.Moralis.Object.extend(
      CASH_POSITION_COLLECTION
    );
    const object = new CashPositionCollection();
    object.set("value", value);
    object.set("username", username.toLowerCase());
    return object.save();
  };

  public async saveStatsValue(walletsStats: WalletsStats) {
    const StatsCollection = this.Moralis.Object.extend(STATS_COLLECTION);
    const object = new StatsCollection();
    this.copyProperties(walletsStats, object);
    return object.save();
  };

  private copyProperties(object: any, moralisObject: any) {
    Object.keys(object).forEach(key => {
      moralisObject.set(key, object[key]);
    });
  };
}
const singleton = new MoralisRepository();
export default singleton;
