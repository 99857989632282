import { FC } from 'react';
import { styled } from '@mui/system';

const Root = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  text-align: center;
  color: white;
`;

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  return (
    <Root>
      <p>QUANTY GmbH {new Date().getFullYear()} | Imprint | Privacy Policy | currently an Alpha Version</p>
    </Root>
  );
};
