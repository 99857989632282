export async function moralisSubscribe(
    collection: string,
    username: string | null,
    Moralis: any,
    onUpdate: any,
    onCreate: any,
    onLeave: any,
) {
    const query = new Moralis.Query(collection);

    if (username) {
        // query.equalTo('username', username?.toLowerCase() ?? 'no');
    }
    const subscription = await query.subscribe();
    subscription.on('update', (object: any) => {
        onUpdate(object);
    });

    subscription.on('create', (object: any) => {
        onCreate(object);
    });
    subscription.on('leave', (object: any) => {

        onLeave(object);
    });

    return subscription;
}