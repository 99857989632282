import { NftPriceInfo } from "../Types/Collections/NFTPriceInfo";
import { NFT_PRICE_INFO_COLLECTION } from "./constants";

export async function saveOrUpdateNftDetails(isExist: boolean, username: string, nftPriceInfo: NftPriceInfo, Moralis: any) {
    const NftPriceInfoCollection = Moralis.Object.extend(NFT_PRICE_INFO_COLLECTION);
    let nftObject = {} as any;

    if (isExist) {
        const query = new Moralis.Query(NFT_PRICE_INFO_COLLECTION);
        query.equalTo("tokenAddress", nftPriceInfo.tokenAddress);
        query.equalTo("walletAddress", nftPriceInfo.walletAddress);
        query.equalTo("tokenId", nftPriceInfo.tokenId);
        query.equalTo("username", username.toLowerCase());
        nftObject = await query.first();
    }
    else {
        nftObject = new NftPriceInfoCollection();
    }

    nftObject.set('username', username.toLowerCase());
    nftObject.set('tokenAddress', nftPriceInfo.tokenAddress);
    nftObject.set('walletAddress', nftPriceInfo.walletAddress);
    nftObject.set('name', nftPriceInfo.name);
    nftObject.set('tokenId', nftPriceInfo.tokenId);
    nftObject.set('acNative', nftPriceInfo.acNative);
    nftObject.set('floorAcNative', nftPriceInfo.floorAcNative);
    nftObject.set('staticPriceNative', nftPriceInfo.staticPriceNative);
    nftObject.set('description', nftPriceInfo.description);

    return nftObject.save();
};