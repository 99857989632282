import React from 'react';
import ReactDOM from 'react-dom/client';
import { MoralisProvider } from 'react-moralis';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ENVIRONMENT } from './Utils/constants';

const URL = ENVIRONMENT === 'production' ? process.env.REACT_APP_MORALIS_URL : process.env.REACT_APP_MORALIS_URL_DEV;
const APP_ID =
  ENVIRONMENT === 'production' ? process.env.REACT_APP_MORALIS_APP_ID : process.env.REACT_APP_MORALIS_APP_ID_DEV;

const Application = () => {
  if (!APP_ID || !URL)
    throw new Error('Missing Moralis Application ID or Server URL. Make sure to set your .env file.');

  return (
    <MoralisProvider appId={APP_ID ?? 'Invalid'} serverUrl={URL ?? 'Invalid'}>
      <App />
    </MoralisProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Application />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
