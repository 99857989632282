export const ENVIRONMENT: string = process.env.REACT_APP_ENV ?? 'development';

export const DEFAULT_OPENSEA_NAME: string = 'OpenSea Shared Storefront';
export const NULL_WALLET: string = '0x0000000000000000000000000000000000000000';
export const WETH: string = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756CC2'.toUpperCase();

export const OPENSEA_STORE_CONTRACT: string = '0x495f947276749ce646f68ac8c248420045cb7b5e';

export const IPFS_GATEWAY: string = 'https://gateway.moralisipfs.com/ipfs/';
export const IPFS_PROTOCOL: string = 'ipfs://';
export const UsdToEur: number = 0.93;

export const ACCOUNT_COLLECTION = 'Subwallets';
export const NFT_PRICE_INFO_COLLECTION = 'NftPriceInfo';
export const TRANSACTIONS_COLLECTION = 'Transactions';
export const SHARES_COLLECTION = 'Shares';
export const CASH_POSITION_COLLECTION = 'CashPosition';
export const WALLET_STATUS = 'WalletStatus';
export const STATS_COLLECTION = 'Stats';
export const FLOOR_PRICES_COLLECTION = 'FloorPrices';
export const BlockToPrice = 'EthHistoricPrice';

export const ETH_DIGEST: number = 5;
export const FIAT_DIGEST: number = 2;
export const DEFAULT_ETH_PRICE: number = 1;
export const DEFAULT_LAST_FLOOR_PRICE: number = 0;

export const FLOOR_PRICE_SYNC_LIMIT = 1;

export const DEFAULT_PAGE_SIZE = 50;

export enum Currency {
  USD = '$',
  EUR = '€',
}
export const PERIOD_VARIANTS = [
  { value: 7, text: '7 Tage' },
  { value: 14, text: '14 Tage' },
  { value: 21, text: '21 Tage' },
];

export const CHAIN_TO_CURRENCY: any = {
  eth: 'ETH',
};

export const OPENSEA_NFT_COLLECTION_LINK: string = 'https://opensea.io/collection/';
export const OPENSEA_NFT_ASSET_LINK: string = 'https://opensea.io/assets/ethereum/';
export const ETHERSCAN_ADRESS_LINK: string = 'https://etherscan.io/address/';
export const ETHERSCAN_TOKEN_HASH_LINK: string = 'https://etherscan.io/tx/';

export const DEFAULT_DATE: Date = new Date(-1);
