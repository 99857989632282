import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { NftPriceInfo } from '../../Types/Collections/NFTPriceInfo';
import NFTAvatar from '../NFTAvatar';
import { ModalRoot } from '../Reusable/styledComponents';

interface NFTEditModalProps {
  open: boolean;
  handleClose: () => void;
  handleOk: (data: NftPriceInfo, shouldSave: boolean) => void;
  name: string;
  id: string;
  tokenId: string;
  walletId: string;
  imageUrl?: string;
  detectedAc: number;
  tokenPriceInfo: NftPriceInfo | null;
}

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

interface InnerData {
  ac: number;
  floorAc: number;
  description: string;
  staticPrice: number;
}
const NFTEditModal: FC<NFTEditModalProps> = ({
  open,
  handleClose,
  handleOk,
  name,
  id,
  tokenId,
  walletId,
  imageUrl = 'dummyimage.mp4',
  detectedAc,
  tokenPriceInfo = null,
}) => {
  useEffect(() => {
    if (tokenPriceInfo && open) {
      const nftPriceInnerDataOrDefault: InnerData = {
        ac: tokenPriceInfo ? tokenPriceInfo?.acNative : 0,
        floorAc: tokenPriceInfo ? tokenPriceInfo?.floorAcNative : 0,
        description: tokenPriceInfo ? tokenPriceInfo?.description : '',
        staticPrice: tokenPriceInfo ? tokenPriceInfo?.staticPriceNative : 0,
      };
      setDefaultNftData(nftPriceInnerDataOrDefault);

      setAc(nftPriceInnerDataOrDefault.ac);
      setDescription(nftPriceInnerDataOrDefault.description);
      setStaticPrice(nftPriceInnerDataOrDefault.staticPrice);
      setFloorAc(nftPriceInnerDataOrDefault.floorAc);
    } else {
      cleanUp();
    }
  }, [open, tokenPriceInfo]);

  const [defaultNftData, setDefaultNftData] = useState<InnerData>();
  const [ac, setAc] = useState<number>(0);
  const [floorAc, setFloorAc] = useState<number>(0);
  const [staticPrice, setStaticPrice] = useState<number>(0);
  const [description, setDescription] = useState<string>('');

  const cleanUp = () => {
    setAc(0);
    setDescription('');
    setStaticPrice(0);
    setFloorAc(0);
  };

  const save = () => {
    const nftInfo: NftPriceInfo = {
      chain: '', //TODO:
      tokenId: tokenId,
      walletAddress: walletId,
      tokenAddress: id,
      name: name,
      acNative: ac,
      floorAcNative: floorAc,
      staticPriceNative: staticPrice,
      description: description,
    };
    const shouldSaveToDb = defaultNftData ? !isNewValuesTheSame(nftInfo, defaultNftData) : true;
    handleOk(nftInfo, shouldSaveToDb);
    handleClose();
  };

  const isNewValuesTheSame = (newValues: NftPriceInfo, defaultValue: InnerData): boolean => {
    return (
      defaultValue &&
      newValues.acNative === defaultValue.ac &&
      newValues.staticPriceNative === defaultValue.staticPrice &&
      newValues.description === defaultValue.description &&
      newValues.floorAcNative === defaultValue.floorAc
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalRoot>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <Grid>
            <NFTAvatar imageUrl={imageUrl} width="124px" height="124px" />
          </Grid>
          <Grid style={{ marginLeft: '12px' }}>
            <Typography component={'div'} variant="h6">
              {name}
            </Typography>
            <Typography component={'div'}>TokenAddress {id}</Typography>
            <Typography component={'div'}>TokenID {tokenId}</Typography>
          </Grid>
        </Grid>

        {detectedAc === 0 ? (
          <StyledTextField
            fullWidth
            type="number"
            placeholder="AC (CC)"
            value={ac}
            label={'AC (CC)'}
            onChange={(e) => setAc(+e.target.value)}
          />
        ) : (
          <Typography component={'div'} variant="h6" sx={{ paddingTop: '24px' }}>
            We detected AC automatically, so you cant change AC
          </Typography>
        )}

        <StyledTextField
          fullWidth
          type="number"
          placeholder="FloorAC (CC)"
          label={'FloorAC (CC)'}
          value={floorAc}
          onChange={(e) => setFloorAc(+e.target.value)}
        />

        <StyledTextField
          fullWidth
          type="number"
          placeholder="Static Price (CC)"
          label={'Static Price (CC)'}
          value={staticPrice}
          onChange={(e) => setStaticPrice(+e.target.value)}
        />
        <Button variant="contained" onClick={() => setStaticPrice(0.0000000001)} color={'error'}>
          Is Scam! Set Eval 0,0000000001
        </Button>
        <StyledTextField
          fullWidth
          placeholder="Description"
          label={'Description'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button color={'primary'} variant="contained" type="submit" onClick={save}>
            Save
          </Button>
        </Grid>
      </ModalRoot>
    </Modal>
  );
};

export default NFTEditModal;
