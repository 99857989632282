import { FC, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import PieChartDashboard from './PieChartDashboard';
import { Currency, PERIOD_VARIANTS } from '../../Utils/constants';
import AddShareModal from '../Modals/AddShareModal';
import AddCashPositionModal from '../Modals/AddCashPositionModal';
import {
  GreyText,
  GreyTextBold,
  Hairline,
  StatsContainerRoot,
  StyledListGrid,
  StyledWalletBar,
  Title,
} from '../Reusable/styledComponents';
import { calculateEurValueStringFormat, formatNumber } from '../../Utils/utils';

import { ISubwallet } from '../../Types/Grid/ISubwallet';
import usePrevious from '../../Services/usePrevious';

interface DashboardPageProps {
  onNewPeriod: any;
  onNewShareValue: any;
  onNewCashPosition: any;
  onNewCurrency: any;
  onSelectedWalletIds: any;

  lastShareValue: string;

  nftsCount: number;
  coinsCount: number;
  singleShareValue: string;

  cashPosition: string;
  nftPosition: string;
  cryptoPosition: string;

  portfolioValue: number;

  cryptoGain: string;
  nftValue: number;
  ethPrice: number;
  investedInNftFiat: number;

  subwallets: ISubwallet[];
}

const Overview: FC<DashboardPageProps> = ({
  onSelectedWalletIds,
  onNewPeriod,
  onNewShareValue,
  onNewCashPosition,
  onNewCurrency,
  lastShareValue,
  nftsCount,

  coinsCount,
  nftValue,
  investedInNftFiat,
  nftPosition,
  portfolioValue,
  cryptoPosition,
  cryptoGain,
  singleShareValue,
  cashPosition,
  subwallets,
  ethPrice,
}) => {
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [cashPositionModal, setCashPositionModal] = useState<boolean>(false);

  const [period, setPeriod] = useState<number>(7);
  const [currency, setCurrency] = useState<string>(Currency.EUR);
  const [selectedWallets, setSelectedWallets] = useState<string[]>([]);
  const previousUserWallets = usePrevious(subwallets);
  useEffect(() => {
    if (subwallets && selectedWallets.length === 0) {
      setSelectedWallets(subwallets.map((e) => e.walletId)); //init
    } else if (subwallets && subwallets.length !== previousUserWallets.length) {
      setSelectedWallets(subwallets.map((e) => e.walletId));
    }
  }, [subwallets]);

  const onPeriodChanged = (value: string) => {
    setPeriod(+value);
    onNewPeriod(value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const onCurrencyChanged = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    onNewCurrency(value);
    setCurrency(value);
  };

  const handleSelectedWalletChange = (event: SelectChangeEvent<typeof selectedWallets>) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0) {
      setSelectedWallets(value as string[]);
      onSelectedWalletIds(value); //todo: why 2 states
    }
  };

  const PortfolioToolBar = () => {
    return (
      <>
        <AddShareModal
          open={shareModal}
          handleClose={() => setShareModal(false)}
          handleOk={(e) => {
            onNewShareValue(e);
          }}
        />
        <AddCashPositionModal
          open={cashPositionModal}
          handleClose={() => setCashPositionModal(false)}
          handleOk={(e) => {
            onNewCashPosition(e);
          }}
        />
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item style={{ marginBottom: '20px' }}>
            <Typography component={'div'} variant="h6" style={{ fontWeight: '600' }}>
              My Portfolio
            </Typography>
            <StyledWalletBar>
              {subwallets.length} Wallets - {nftsCount} NFTs - {coinsCount} Crypto Coins
            </StyledWalletBar>
          </Grid>
          <Grid item>
            <Select
              style={{ marginRight: '12px' }}
              value={period.toString()}
              onChange={(e) => onPeriodChanged(e.target.value)}
              size="small"
              label="letzten Tage"
            >
              {PERIOD_VARIANTS.map((e, index) => (
                <MenuItem key={`${index}-timevalue`} value={e.value}>
                  {e.text}
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="label Currency"
              id="Currency"
              value={currency}
              label="Currency"
              onChange={onCurrencyChanged}
              size="small"
            >
              <MenuItem value={Currency.EUR.toString()}>EUR</MenuItem>
              <MenuItem value={Currency.USD.toString()}>USD</MenuItem>
            </Select>

            <Select
              label="Wallets"
              sx={{ width: '180px', marginLeft: '12px' }}
              multiple
              value={selectedWallets}
              renderValue={(wallets) => wallets.map((c) => c.slice(0, 5)).join(', ')}
              MenuProps={MenuProps}
              size="small"
              onChange={handleSelectedWalletChange}
            >
              {subwallets?.map((wallet) => (
                <MenuItem key={wallet.walletId} value={wallet.walletId}>
                  <Checkbox checked={selectedWallets.indexOf(wallet.walletId) > -1} />
                  <ListItemText primary={wallet.walletName} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </>
    );
  };

  const GainStats = () => {
    const nftFiatValue = nftValue * ethPrice;
    const portfolioGainAbsolute = (nftFiatValue * 100) / investedInNftFiat ?? 0;
    const gainValuePercents =
      portfolioGainAbsolute > 100 ? portfolioGainAbsolute - 100 : (100 - portfolioGainAbsolute) * -1;
    const gainValueFiat = nftFiatValue - investedInNftFiat;

    return (
      <StatsContainerRoot container direction="row" justifyContent="space-between">
        <Grid item xs={5}>
          <StyledListGrid>
            <GreyText>
              Shares{' '}
              <Button
                type="submit"
                color="primary"
                onClick={() => {
                  setShareModal(true);
                }}
              >
                Add
              </Button>
            </GreyText>

            <div>{lastShareValue}</div>
          </StyledListGrid>

          <StyledListGrid item>
            <GreyText>
              Cashposition:
              <Button
                type="submit"
                color="primary"
                onClick={() => {
                  setCashPositionModal(true);
                }}
              >
                Add
              </Button>
            </GreyText>
            <div>
              {cashPosition} {currency}
            </div>
          </StyledListGrid>
          <StyledListGrid item>
            <GreyText>Share Value:</GreyText>
            <Title sx={{ fontSize: '20px' }}>
              {singleShareValue} {currency}
            </Title>
          </StyledListGrid>
          <StyledListGrid item>
            <GreyText>Crypto Position:</GreyText>
            <div>
              {cryptoPosition} {currency}
            </div>
          </StyledListGrid>
          <StyledListGrid item>
            <GreyText>NFT Position:</GreyText>
            <div>
              {nftPosition} {currency}
            </div>
          </StyledListGrid>
          <StyledListGrid item>
            <GreyTextBold>Portfolio Value:</GreyTextBold>
            <div>
              {formatNumber(portfolioValue)} {currency}
            </div>
          </StyledListGrid>
        </Grid>
        <Hairline />
        <Grid item xs={5}>
          <StyledListGrid>
            <GreyText>Portfolio without cash: </GreyText>
            <GreyText>Gain</GreyText>
          </StyledListGrid>
          <StyledListGrid>
            <GreyText>
              {formatNumber(portfolioValue)}
              {currency}
            </GreyText>
            <GreyText>{formatNumber(gainValuePercents ? gainValuePercents : 0)}%</GreyText>
          </StyledListGrid>

          <StyledListGrid>
            <GreyText>Invested NFT Value:</GreyText>
            <div>
              {formatNumber(investedInNftFiat)} {currency}
            </div>
          </StyledListGrid>
          <StyledListGrid>
            <GreyText>Current NFT Value:</GreyText>
            <div>
              {calculateEurValueStringFormat(nftValue, ethPrice)} {currency}
            </div>
          </StyledListGrid>
          <StyledListGrid>
            <GreyText>NFT Gain:</GreyText>
            <div>
              {formatNumber(gainValueFiat)} {currency}
            </div>
          </StyledListGrid>

          <StyledListGrid>
            <GreyText>Crypto Value:</GreyText>
            <div>
              {cryptoPosition} {currency}
            </div>
          </StyledListGrid>
          <StyledListGrid>
            <GreyText>Crypto Gain:</GreyText>
            <div>
              {cryptoGain} {currency}
            </div>
          </StyledListGrid>
        </Grid>
      </StatsContainerRoot>
    );
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item md={4} xs={12}>
        <PieChartDashboard
          stableCoinsPosition={parseFloat(cashPosition.replace(',', '.'))}
          cryptoPosition={parseFloat(cryptoPosition.replace(',', '.'))}
          nftPosition={parseFloat(nftPosition.replace(',', '.'))}
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <PortfolioToolBar />
        <GainStats />
      </Grid>
    </Grid>
  );
};

export default Overview;
