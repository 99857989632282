import { FC } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';
import { Navbar } from './Navbar';
import { Footer } from './Footer';

const Root = styled('div')`
  width: 100%;
`;

const ContentRoot = styled(Stack)`
  padding: 64px;
  padding-bottom: 0;
  padding-top: 0;
  margin: 0 auto;
  margin-bottom: 128px;
  max-width: 1860px;
`;

interface PageWrapperProps {
  children: React.ReactNode;
}

const PageWrapper: FC<PageWrapperProps> = ({ children }) => {
  return (
    <Root>
      <Navbar />
      <ContentRoot>{children}</ContentRoot>
      <Footer />
    </Root>
  );
};

export default PageWrapper;
