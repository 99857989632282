import {  WETH } from "../Utils/constants";
import { distinct } from "../Utils/utils";

class MoralisFetchService {
    Moralis: any;

    public init(moralis: any) {
        this.Moralis = moralis;
    };

    public async loadCollection(collectionName: string) {
        return (await this.Moralis.Cloud.run('loadCollection', { collectionName: collectionName }));
    };

    public async blockToPriceDictionary(ethBlocks: any[]) {
        const uniqueBlocks = distinct(ethBlocks, 'block');

        return await this.Moralis.Cloud.run("historicalEthPrices", {
            blocks: uniqueBlocks,
            chain: 'eth',
            address: WETH
        });
    };
}

const singleton = new MoralisFetchService();
export default singleton;