import { FC } from 'react';
import { Grid } from '@mui/material';

import PageWrapper from '../Components/PageWrapper';
import { PageHeadline, PageStyledPaper } from '../Components/Reusable/styledComponents';

const NewsPage: FC = () => {
  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <PageHeadline>News</PageHeadline>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>All about Crypto News..</PageStyledPaper>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sx={{ marginTop: '32px' }}>
        <PageStyledPaper>All about Crypto News..</PageStyledPaper>
      </Grid>
    </PageWrapper>
  );
};

export default NewsPage;
