import { FC } from 'react';
import { useMoralis } from 'react-moralis';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from './config/routes';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useMoralis();
  const location = useLocation();

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate replace={true} to={ROUTES.LOGIN} state={{ from: `${location.pathname}${location.search}` }} />
  );
};

export default PrivateRoute;
