import Moralis from 'moralis/types';
import { createContext, FC } from 'react';
import { useMoralis } from 'react-moralis';
import { AuthenticateOptions } from 'react-moralis/lib/hooks/core/useMoralis/_useMoralisAuth';

export const UserContext = createContext<UserProps | null>(null);

export interface UserProps {
  user: Moralis.Attributes | null;
  authenticate: (options?: AuthenticateOptions | undefined) => Promise<Moralis.User<Moralis.Attributes> | undefined>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
}

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const { authenticate, logout, isAuthenticated, user } = useMoralis();

  return (
    <UserContext.Provider
      value={{
        user: user,
        authenticate: () => {
          return authenticate({
            provider: 'web3Auth',
            clientId: 'BJhWKICiIx7FIBprmRZZcYt5S1YFTCw7PAviCqQlLfu5JfXsY6AuLfFcH9CZzVeM5Fw9bAKqoY3-gTYNABTnrWw',
            appLogo: 'https://i.postimg.cc/kXnXmd83/android-chrome-256x256.png',
            loginMethodsOrder: ['google', 'twitter', 'discord'],
            theme: 'dark',
          });
        },
        logout: async () => {
          localStorage.removeItem('Web3Auth-cachedAdapter');
          return logout();
        },
        isAuthenticated: isAuthenticated,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
