import React, { FC, useEffect, useState } from "react";
import { styled } from "@mui/system";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ITransferGridItem } from "../../Types/Grid/ITransferGridItem";
import { FungibleToken } from "../../Types/FungibleTokenInfo";
import NonFungibleTransfers from "./NonFungibleTransfers";
import FungibleTransfers from "./FungibleTransfers";
import { Grid } from "@mui/material";

interface TransferProps {
  fungibleTransfers: ITransferGridItem[];
  nonFungibleTransfers: ITransferGridItem[];
  syncing: boolean;

}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const Transfers: FC<any> = ({
  fungibleTransfers,
  nonFungibleTransfers,
  syncing
}: TransferProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (fungibleTransfers && nonFungibleTransfers) {
    }
  }, [fungibleTransfers, nonFungibleTransfers])

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nft trust label"
          >
            <Tab label="NON Fungible" {...a11yProps(0)} />
            <Tab label="Fungible" {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <TabPanel value={value} index={0}>
          Non fungible tokens
          <NonFungibleTransfers rows={[...nonFungibleTransfers]} syncing={syncing} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Fungible tokens
          <FungibleTransfers rows={fungibleTransfers} syncing={syncing} />
        </TabPanel>
      </Grid>
    </>
  );
};

export const In = styled("span")`
  color: green;
`;
export const Out = styled("span")`
  color: red;
`;
