import React, { FC, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TransferType } from '../../Types/TransferType';
import { formatNumber, uiDate } from '../../Utils/utils';
import { In, Out } from './Transfers';
import { DEFAULT_PAGE_SIZE, WETH } from '../../Utils/constants';
import { Skeleton } from '@mui/material';

const FungibleTransfers: FC<any> = ({ rows, ethPrice, syncing }: any) => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const columns = [
    {
      field: 'walletId',
      headerName: 'Wallet',
      width: 250,
      renderCell: (params: any) => {
        return (
          <>
            <span>{params.row.walletId.slice(0, 6)}</span>/
            <p>
              {params.row.chain} - {params.row.fromWalletName}
            </p>
          </>
        );
      },
    },
    { field: 'hash', headerName: 'TxHash', width: 125 },
    {
      field: 'token',
      headerName: 'Token',
      width: 200,
      renderCell: (params: any) => {
        return <>{params.row.token.tokenAddress.slice(0, 6)}</>;
      },
      valueGetter: (params: any) => params.row.token.tokenAddress,
    },
    {
      field: 'what',
      headerName: 'What',
      width: 75,
      renderCell: (params: any) => {
        return <>{params.row.type === TransferType.IN ? <In>{params.row.type}</In> : <Out>{params.row.type}</Out>}</>;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 125,
      renderCell: (params: any) => {
        return <> {formatNumber(params.row.amount, 0)}</>;
      },
    },
    { field: 'nativeValue', headerName: 'ETH Value', width: 125 },
    { field: 'eurValue', headerName: 'EUR', width: 150 },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      type: 'Date',
      renderCell: (params: any) => {
        return <>{uiDate(params.row.date, true)}</>;
      },
    },
  ];

  return (
    <>
      {ethPrice}
      <div style={{ height: 400, width: '100%' }}>
        {!syncing ? (
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              },
            }}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 50, 100]}
            pagination
          />
        ) : (
          <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
        )}
      </div>
    </>
  );
};

export default FungibleTransfers;
