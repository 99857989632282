import { FC, useContext } from 'react';

import { Button, Stack, styled } from '@mui/material';

import { useMoralis } from 'react-moralis';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../config/routes';
import { UserContext } from '../Context/userContext';
import Logo from '../images/quanty_logo.svg';

import PageWrapper from '../Components/PageWrapper';

const ConnectButton = styled(Button)`
  width: 400px;
  height: 60px;
  background-color: #1dffd9;
  color: black;
  font-weight: 600;
  &:hover {
    background-color: #95ff96;
    transition: all 500ms ease-in-out;
  }
`;

const NavbarLogo = styled('img')`
  margin-bottom: 18px;
  width: 100%;
`;

const LoginPage: FC = () => {
  const { isAuthenticated } = useMoralis();
  const context = useContext(UserContext);

  return isAuthenticated ? (
    <Navigate replace={true} to={ROUTES.HOME} />
  ) : (
    <PageWrapper>
      <Stack spacing={2}>
        <NavbarLogo src={Logo} alt="NFTTRUST LOGO" />
        <Stack direction="column" alignItems="center" justifyContent="center">
          <ConnectButton variant="contained" onClick={() => context?.authenticate()}>
            Login and connect your Wallet
          </ConnectButton>
          <div style={{ marginTop: '24px' }}> - OR - </div>
          <Button variant="outlined" sx={{ marginTop: '24px' }} onClick={() => alert('Feature will be enabled soon!')}>
            See Dashboard with Demo Account
          </Button>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default LoginPage;
