import React, { FC, useContext } from 'react';
import { styled, useTheme, css } from '@mui/system';
import { Avatar, Button, InputAdornment, Paper, Stack, TextField, useMediaQuery } from '@mui/material';
import { Search } from '@mui/icons-material';

import { UserContext } from '../Context/userContext';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';

const Root = styled(Paper)`
  margin-bottom: 24px;
  border-radius: 0;
`;

const NavigationContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
`;

const LogoutArea = styled('div')`
  display: flex;
`;

const SearchBar = styled(TextField)<{ matches: boolean }>`
  margin-left: 14px;
  ${({ matches }) =>
    matches &&
    css`
      display: none;
    `}
`;

const UserSlug = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
  margin-right: 15px;
`;

const Profile = styled('div')`
  border: 1px;
  display: flex;
`;

export const Navbar: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const context = useContext(UserContext);

  const AccountMenu = () => {
    return (
      <>
        {context?.isAuthenticated && (
          <LogoutArea>
            <Profile>
              <Avatar style={{ marginRight: '12px' }} />
              <UserSlug>{context?.user?.getUsername()}</UserSlug>
              <Button onClick={context?.logout}>
                <LogoutIcon>Logout</LogoutIcon>
              </Button>
            </Profile>
          </LogoutArea>
        )}
      </>
    );
  };

  return (
    <Root elevation={1}>
      <NavigationContainer>
        <SearchBar
          matches={matches}
          size="small"
          placeholder="Search for NFT or Wallets"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row" spacing={2}>
          <AccountMenu />
          <ColorModeButton />
        </Stack>
      </NavigationContainer>
    </Root>
  );
};

const ColorModeButton = () => {
  const [mode, setMode] = React.useState(false);
  return (
    <Button
      onClick={() => {
        setMode(!mode);
      }}
    >
      {!mode ? <LightModeIcon /> : <DarkModeIcon />}
    </Button>
  );
};
