import { styled, ThemeProvider } from '@mui/material';

import { CssBaseline } from '@mui/material/';
import theme from './Styles/theme';
import { Header } from './Components/Header';
import { AuthProvider } from './Context/userContext';

import AppRouting from './AppRouting';

const Root = styled('div')`
  display: flex;
  background: #4ecdc4;
  background: -webkit-linear-gradient(to top, #556270, #4ecdc4);
  background: linear-gradient(to top, #556270, #4ecdc4);
  min-height: 100vh;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Root>
          <Header />
          <AppRouting />
        </Root>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
