import { Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import * as React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

const ToolTipRoot = styled("div")`
  background-color: white;
  padding: 12px;
  border: 1px solid #e5e7eb;
`;

interface AreaChartDashboardProps {
  shareValueData: any;
  syncing: boolean;
}

const AreaChartDashboard: React.FC<AreaChartDashboardProps> = ({
  shareValueData,
  syncing
}) => {
  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const e = payload as any[];
      return (
        <ToolTipRoot>
          <p>{`${moment(label).format("DD/MM/YYYY - hh:mm:ss")}`}</p>
          <p>{`Share Value: ${payload?.[0].value}€`}</p>
        </ToolTipRoot>
      );
    }

    return null;
  };

  function formatXAxis(tickItem: string) {
    return moment(tickItem).format("DD/MM/YYYY");
  }

  const dummyColor = "#003953";

  return (
    <div style={{ width: "100%", height: "400px", overflow: "hidden" }}>
      <ResponsiveContainer>
        {
          !syncing ?
            <AreaChart data={shareValueData}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={dummyColor} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={dummyColor} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="updatedAt" tickFormatter={formatXAxis} />
              <YAxis tickFormatter={(value) => `${value}€`} />
              <Tooltip content={<CustomTooltip />} />
              <Area
                name="Share Value"
                type="monotone"
                dataKey="shareValue"
                stroke="#24262f"
                fillOpacity={1}
                fill="url(#colorUv)"
                activeDot={{ r: 8 }}
              />
            </AreaChart> :
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
        }

      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartDashboard;
