import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import React from "react";

export const CircularProgressWithLabel = React.forwardRef((
  props: CircularProgressProps & { value: number }, ref
) => {
  const color = props.value === 100 ? 'success' : 'secondary';

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} color={color} ref={ref}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
});