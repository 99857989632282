import React, { FC, useEffect, useRef } from 'react';
import MoralisDataService from '../Services/MoralisFetchService';
import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { saveOrUpdateNftDetails } from '../Utils/moralisUtils';
import { useMoralis } from 'react-moralis';
import { INFTAssetGrid as INFTAssetGridItem } from '../Types/Grid/INFTAssetGrid';
import { ISubwallet } from '../Types/Grid/ISubwallet';
import { Transfers } from '../Components/Transfers/Transfers';
import { ITransferGridItem } from '../Types/Grid/ITransferGridItem';
import {
  SHARES_COLLECTION,
  STATS_COLLECTION,
  Currency,
  ACCOUNT_COLLECTION as SUBWALLET_COLLECTION,
  FLOOR_PRICES_COLLECTION,
  OPENSEA_STORE_CONTRACT,
} from '../Utils/constants';
import NftAssets from '../Components/NFTs/NFTAssets';
import {
  suma,
  toDictionaryOneToOne,
  calculateEurValueStringFormat,
  formatNumber,
  calculateEurValueNumber,
  calcEvaluationNative,
  usdValue,
} from '../Utils/utils';
import Overview from '../Components/Dashboard/Overview';

import { NftPriceInfo } from '../Types/Collections/NFTPriceInfo';
import { WalletsStats } from '../Types/WalletsStats';
import MoralisRepository from '../Services/MoralisRepository';
import { usePrevious } from '../Services/usePrevious';
import WalletsInfo from '../Components/NFTs/WalletsInfo';
import { IWallet } from '../Types/IWallet';
import { moralisSubscribe } from '../Utils/moralis.subscription';
import Moralis from 'moralis/types';
import AreaChartDashboard from '../Components/Dashboard/AreaChartDashboard';
import PageWrapper from '../Components/PageWrapper';
import { PageHeadline, PageStyledPaper } from '../Components/Reusable/styledComponents';

const StyledHeadline = styled(Typography)`
  font-weight: bold;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 24px;
`;

const initialState = {
  subwallets: [],
  selectedWalletIds: [],
  walletsCoins: [],
  viewedNfts: [],
  nftTransfers: [],
  ftTransfers: [],
  totalCoinsEthValue: 0,
  totalNftsEthValue: 0,
};

const DashboardPage: FC = () => {
  const { Moralis, isInitialized, user, isAuthenticated } = useMoralis();

  const [subwallets, setSubwallets] = React.useState<ISubwallet[]>(initialState.subwallets);

  const [selectedWalletIds, setSelectedWalletIds] = React.useState<any[]>(initialState.selectedWalletIds);

  const [, setPeriod] = React.useState<number>();
  const [ethPrice, setEthPrice] = React.useState<number>(1);
  const [currency, setCurrency] = React.useState<string>(Currency.EUR);

  const [totalCoinsEthValue, setCoinsEthValue] = React.useState<number>(initialState.totalCoinsEthValue);
  const [totalNftsEthValue, setTotalNftsEthValue] = React.useState<number>(initialState.totalNftsEthValue);

  const [walletsCoins, setWalletsCoins] = React.useState<any[]>([]);

  const [viewedNfts, setViewedNftAssets] = React.useState<INFTAssetGridItem[]>([]);
  const [viewedFungibleTransfers, setViewedFungibleTransfers] = React.useState<ITransferGridItem[]>([]);
  const [viewedNonFungibleTransfers, setViewedNonFungibleTransfers] = React.useState<ITransferGridItem[]>([]);

  const [walletsCoinsCount, setWalletsCoinsCount] = React.useState<number>(0);
  const [cashPostion, setCashPosition] = React.useState<number>(0);
  const [cashPositions, setCashPositions] = React.useState<any[]>([]);
  const [walletsToNativeValue, setWalletsToNativeValue] = React.useState<any>({});

  const [shares, setShares] = React.useState<any[]>([]);
  const [syncing, setSyncing] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState<boolean>(false);
  const previousSyncing = usePrevious(syncing);
  const previousReload = usePrevious(reload);
  const previousAuthenticated = usePrevious(isAuthenticated);

  const [accountSubscriptionExist, setAccountsSubscribed] = React.useState(false);
  const [floorPricesSubscriptionExist, setFloorPricesSubscribed] = React.useState(false);
  const [linechartStats, setLineChartStats] = React.useState<any[]>([]);
  const [portfolioValue, setPortfolioValue] = React.useState<number>(0);
  const [singleShareValue, setSingleShareValue] = React.useState<number>(0);
  const [lastShareValue, setLastShareValue] = React.useState<string>('0');
  const [walletsStatus, setWalletsStatus] = React.useState<any>({});
  const walletsStatusRef = useRef();
  walletsStatusRef.current = walletsStatus;

  useEffect(() => {
    onNewPortfolioValue(false);
  }, [shares.length, totalNftsEthValue, totalCoinsEthValue, cashPostion, ethPrice]);

  useEffect(() => {
    setSelectedWalletIds(subwallets.map((c) => c.walletId));
  }, [subwallets]);

  useEffect(() => {
    const totalEth = suma(viewedNfts, 'evaluationNative');
    setTotalNftsEthValue(+totalEth.toFixed(3));
  }, [viewedNfts, viewedNfts.length]);

  useEffect(() => {
    setViewedNftAssets((assets) => {
      return assets.filter((asset) =>
        selectedWalletIds.filter((v, i, a) => a.indexOf(v) === i).includes(asset.walletId),
      );
    });
    setViewedFungibleTransfers((transfers) => {
      return transfers.filter(
        (asset) => selectedWalletIds.includes(asset.from) || selectedWalletIds.includes(asset.to),
      );
    });
    setViewedNonFungibleTransfers((transfers) => {
      const newTransfers = transfers.filter(
        (asset) => selectedWalletIds.includes(asset.from) || selectedWalletIds.includes(asset.to),
      );
      console.log('setViewedNonFungibleTransfers', transfers.length, newTransfers.length);
      return newTransfers;
    });
    setWalletsCoinsCount(() => {
      return walletsCoins
        .filter((walletCois) => selectedWalletIds.includes(walletCois.walletId))
        .flatMap((e) => e.coins).length;
    });

    setCoinsEthValue(() => {
      const selectedWalletsTotalNative = suma(
        selectedWalletIds.map((id) => {
          return +(walletsToNativeValue[id]?.eth ?? 0) + +(walletsToNativeValue[id]?.weth ?? 0);
        }),
      );
      return selectedWalletsTotalNative;
    });
  }, [selectedWalletIds, walletsCoins, walletsToNativeValue]);

  useEffect(() => {
    async function init() {
      MoralisDataService.init(Moralis);
      MoralisRepository.init(Moralis);
    }

    async function loadData() {
      const walletsByAccount: ISubwallet[] = isAuthenticated
        ? await Moralis.Cloud.run('GetSubWallets', {
            username: user?.getUsername(),
          })
        : [];
      setSubwallets(walletsByAccount);
      const accountInfo: any = await Moralis.Cloud.run('accountPortfolio', {
        username: user?.getUsername(),
      });

      const { coinsData, totalValue, currentEthPriceUsd, accountStatus, addressToValue, cashPositions } = accountInfo;

      if (!accountSubscriptionExist) {
        await subscribeOnAccounts();
      }

      if (!floorPricesSubscriptionExist) {
        await subscribeOnFloorPrices();
      }

      //todo: FETCH_ALL
      const shares = await MoralisDataService.loadCollection(SHARES_COLLECTION);
      const linechartStatsfromDB = await MoralisDataService.loadCollection(STATS_COLLECTION);

      if (accountStatus) {
        const walletsStatusInner = toDictionaryOneToOne(accountStatus, 'walletId');
        setWalletsStatus(walletsStatusInner);
      }
      const totalCoinsEthValue = totalValue.eth + totalValue.weth;

      setWalletsToNativeValue(addressToValue);

      setViewedNftAssets(accountInfo.nfts);
      setViewedFungibleTransfers(accountInfo.transfers.ft);
      setViewedNonFungibleTransfers(accountInfo.transfers.nft);

      setEthPrice(currentEthPriceUsd);

      setWalletsCoins(coinsData);
      setWalletsCoinsCount(coinsData.flatMap((d: any) => d.coins).length);

      const totalEth = suma(accountInfo.nfts, 'evaluationNative');
      setTotalNftsEthValue(+totalEth.toFixed(3));
      setCoinsEthValue(+totalCoinsEthValue.toFixed(3));
      setShares(shares);
      setCashPositions(cashPositions);
      setCashPosition(cashPositions.length > 0 ? cashPositions?.at(-1)?.value : 0);

      setLineChartStats(linechartStatsfromDB);
      setSyncing(false);
    }

    async function preload() {
      await init();
      const prevSyncState = previousSyncing;

      if (previousReload !== reload) {
        await loadData();
        return;
      }

      if (prevSyncState === false && syncing === true) {
        return;
      }
      if (!isAuthenticated) {
        return;
      }
      if (prevSyncState === syncing) {
        if (isInitialized) {
          await loadData();
        }
      }
    }

    if (isInitialized) {
      preload();
    }
    if (previousAuthenticated === true && isAuthenticated === false) {
      reset();
    }
  }, [isInitialized, isAuthenticated, syncing, reload]);

  function reset() {
    setSubwallets([]);
    setLineChartStats([]);
    setShares([]);
    setViewedNftAssets(initialState.viewedNfts);
    setViewedFungibleTransfers(initialState.ftTransfers);
    setViewedNonFungibleTransfers(initialState.nftTransfers);
    setCashPosition(0);
    setCashPositions([]);
    setShares([]);
    setLastShareValue('0');
    setSingleShareValue(0);
    setCoinsEthValue(0);
    setTotalNftsEthValue(0);
    setWalletsCoins([]);
    setWalletsCoinsCount(0);
  }

  const walletsByIdShouldReload: any = {};
  async function onAnalysisDone(wallet: IWallet) {
    const walletStatus = walletsByIdShouldReload[wallet.walletId];
    if (!walletStatus || walletStatus === true) {
      setReload(!reload);
      walletsByIdShouldReload[wallet.walletId] = false;
    } else {
      return;
    }
  }

  const subscribeOnAccounts = async () => {
    const username = user?.getUsername()?.toLowerCase() ?? 'n/a';
    console.log(`Client do subscribe on ${SUBWALLET_COLLECTION}, username:${username} `);
    const subscription = await moralisSubscribe(
      SUBWALLET_COLLECTION,
      username,
      Moralis,
      async (updatedObject: Moralis.Object) => {
        setSubwallets((subWallets) => {
          const walletAddress: string = updatedObject.get('walletId').toLowerCase();
          const updatedUsername: string = updatedObject.get('username').toLowerCase();

          if (username !== updatedUsername) {
            return subWallets;
          }

          console.log(`Client ${updatedUsername} receive update from user ${updatedUsername}}`);

          const updatedWalletIndex = subWallets.findIndex(
            (c) => c.walletId === walletAddress.toLowerCase() && updatedUsername === username,
          );

          if (updatedWalletIndex == -1) return subWallets;
          const subwalletNewState = updatedObject.attributes as ISubwallet;
          const prevSubwallet = subWallets[updatedWalletIndex];

          if (subwalletNewState.syncing === false && prevSubwallet.syncing === true) {
            const wallet: IWallet = {
              walletId: subwalletNewState.walletId,
              chain: subwalletNewState.chain,
              name: subwalletNewState.walletName,
            };
            onAnalysisDone(wallet); //not awaited
          }

          const newState = [...subWallets];
          newState[updatedWalletIndex] = subwalletNewState;
          return newState;
        });
      },
      (createdObject: Moralis.Object) => {
        const updatedUsername: string = createdObject.get('username').toLowerCase();
        if (username !== updatedUsername) {
          return;
        }
        setSubwallets((value) => {
          const subWallets = [...value, createdObject.attributes as ISubwallet];
          return subWallets;
        });
      },
      (onLeave: Moralis.Object) => {},
    );

    setAccountsSubscribed(true);
  };

  const subscribeOnFloorPrices = async () => {
    const subscription = await moralisSubscribe(
      FLOOR_PRICES_COLLECTION,
      null,
      Moralis,
      (updated: Moralis.Object) => {
        const fp = updated.attributes;
        setViewedNftAssets((oldNfts) => {
          const nfts =
            fp.tokenAddress === OPENSEA_STORE_CONTRACT
              ? oldNfts.filter(
                  (nft) => nft.tokenAddress === fp.tokenAddress && nft.tokenId.slice(0, 6) === fp.tokenId.slice(0, 6),
                )
              : oldNfts.filter((nft) => nft.tokenAddress === fp.tokenAddress);
          if (nfts.length === 0) {
            return oldNfts;
          }
          const updatedNfts: INFTAssetGridItem[] = nfts.map((asset) => {
            asset.lastFloorPriceNative = fp.lastFloorPrice;
            asset.floorHistorical = fp.floorHistorical;
            return asset;
          });
          return [...oldNfts, ...updatedNfts];
        });
      },
      (created: Moralis.Object) => {},
      (onLeave: Moralis.Object) => {},
    );

    setFloorPricesSubscribed(true);
  };

  const onNewPortfolioValue = (
    withDbUpdate: boolean,
    newCashPositions: any[] | null = null,
    newShares: any[] | null = null,
  ) => {
    if (!newCashPositions) newCashPositions = cashPositions;
    if (!newShares) newShares = shares;

    const stats: WalletsStats = {
      timestamp: new Date(),
      shareValue: 0,
      lastShareValue: 0,
      nftPosition: totalNftsEthValue,
      cryptoPosition: totalCoinsEthValue,
      cashPosition: 0,
      portfolioValue: 0,
    };

    const nftsWithCoinsInEur = calculateEurValueNumber(ethPrice, totalNftsEthValue + totalCoinsEthValue);

    if (newCashPositions) {
      const currentCashPosition = newCashPositions.length > 0 ? newCashPositions?.at(-1)?.value : 0;
      stats.cashPosition = +currentCashPosition;
      setCashPosition(currentCashPosition);
    }
    const lastCashPosition = stats.cashPosition ? stats.cashPosition : cashPostion;
    const portfolioValueInner = nftsWithCoinsInEur + lastCashPosition;
    stats.portfolioValue = portfolioValueInner;
    setPortfolioValue(portfolioValueInner);

    if (newShares.length > 0) {
      const lastShareValue = newShares?.at(-1)?.value;
      const shareKoeficient = (portfolioValueInner / lastShareValue).toFixed(2);
      stats.shareValue = +shareKoeficient;
      stats.lastShareValue = +lastShareValue;
      setLastShareValue(formatNumber(lastShareValue));
      setSingleShareValue(+shareKoeficient);
    }
    setCashPositions(newCashPositions);
    setShares(newShares);

    if (withDbUpdate) {
      MoralisRepository.saveStatsValue(stats).then((d) => {
        setLineChartStats([...linechartStats, stats]);
      });
    }
  };

  const handleNewNFTDetails = async (nftInfo: NftPriceInfo, shouldUpdate: boolean) => {
    if (!shouldUpdate) return;
    if (!!user && !user?.getUsername()) return;
    const index = viewedNfts.findIndex((d) => d.tokenAddress === nftInfo.tokenAddress && d.tokenId === nftInfo.tokenId);
    if (index === -1) throw Error('NFT not found');
    const isNftPriceInited = !!viewedNfts[index]?.tokenPriceInfo;
    await saveOrUpdateNftDetails(isNftPriceInited, user?.getUsername() ?? '', nftInfo, Moralis);
    const updatedNft = viewedNfts[index];
    viewedNfts[index].tokenPriceInfo = nftInfo;

    const evaluation = calcEvaluationNative(
      nftInfo,
      updatedNft?.lastFloorPriceNative?.price ?? 0,
      updatedNft.automaticDetectedPriceInNativeToken,
    );

    updatedNft.evaluationNative = evaluation.evaluationValue;
    updatedNft.evaluationFiat = usdValue(ethPrice, evaluation.evaluationValue);
    updatedNft.isEdited = evaluation.isEdited;
    updatedNft.floorPriceAc = evaluation.floorPriceAc;
    setViewedNftAssets((prev: any[]) => {
      let copy = [...prev];
      copy[index] = updatedNft;
      return copy;
    });
  };

  const handleNewShareValue = async (value: number) => {
    await MoralisRepository.saveShareValue(value, new Date());
    const newShare = {
      value: value,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const newShares = [...shares, newShare];
    onNewPortfolioValue(true, null, newShares);
  };

  const handleNewCashPosition = async (value: number) => {
    if (!user?.getUsername()) return;
    await MoralisRepository.saveCashPositionValue(user?.getUsername() ?? '', value, new Date());
    const newCash = {
      value: value,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const newCashPositions = [...cashPositions, newCash];
    onNewPortfolioValue(true, newCashPositions, null);
  };

  const handleNewNameWallet = async (name: string, wallet: string) => {
    setSyncing(true);
    await Moralis.Cloud.run('UpdateSubwalletName', {
      username: user?.getUsername(),
      wallet: wallet,
      chain: 'eth',
      name: name,
    });
    setReload(!reload);
  };

  const handleNewWallet = async (walletAddress: string, walletName: string) => {
    const addSubwalletRequest = {
      walletId: walletAddress.toLowerCase(),
      chain: 'eth',
      username: user?.getUsername(),
      name: walletName,
    };

    await Moralis.Cloud.run('AddSubwallet', addSubwalletRequest).catch((e) => {
      alert(e);
      return;
    });
  };

  const handleDeleteWallet = async (walletId: string) => {
    await Moralis.Cloud.run('RemoveSubwallet', {
      username: user?.getUsername(),
      walletId: walletId,
    });
    setSubwallets((oldSubwallets) => {
      return oldSubwallets.filter((d) => d.walletId !== walletId);
    });
    walletsByIdShouldReload[walletId] = true;
  };

  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <PageHeadline>Dashboard</PageHeadline>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>
            <Overview
              ethPrice={ethPrice}
              subwallets={subwallets}
              investedInNftFiat={suma(viewedNfts.map((c) => c.spentUsd).filter((c) => !!c))}
              cryptoGain={'0'}
              lastShareValue={lastShareValue}
              nftValue={totalNftsEthValue}
              cashPosition={formatNumber(cashPostion)}
              cryptoPosition={calculateEurValueStringFormat(totalCoinsEthValue, ethPrice) ?? '0'}
              nftPosition={calculateEurValueStringFormat(+totalNftsEthValue, ethPrice) ?? '0'}
              coinsCount={walletsCoinsCount}
              nftsCount={suma(viewedNfts, 'amount')}
              singleShareValue={formatNumber(singleShareValue)}
              portfolioValue={portfolioValue}
              onNewShareValue={handleNewShareValue}
              onNewCashPosition={handleNewCashPosition}
              onNewPeriod={setPeriod}
              onNewCurrency={setCurrency}
              onSelectedWalletIds={setSelectedWalletIds}
            />
          </PageStyledPaper>
        </Grid>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>
            <Grid item>
              <StyledHeadline>Share Value Progression</StyledHeadline>
            </Grid>

            <AreaChartDashboard shareValueData={linechartStats} syncing={syncing || !isAuthenticated} />
          </PageStyledPaper>
        </Grid>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>
            <Grid item>
              <StyledHeadline>NFT Assets</StyledHeadline>
            </Grid>
            <NftAssets
              currency={currency}
              rows={viewedNfts}
              onNewNftDetails={handleNewNFTDetails}
              ethPrice={ethPrice}
              syncing={syncing || !isAuthenticated}
            />
          </PageStyledPaper>
        </Grid>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>
            <Grid item>
              <StyledHeadline>Recent Activites</StyledHeadline>
            </Grid>
            <Transfers
              nonFungibleTransfers={viewedNonFungibleTransfers}
              fungibleTransfers={viewedFungibleTransfers}
              ethPrice={ethPrice}
              syncing={syncing || !isAuthenticated}
            />
          </PageStyledPaper>
        </Grid>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>
            <Grid item>
              <StyledHeadline>Connected Wallets</StyledHeadline>
            </Grid>
            <WalletsInfo
              rows={subwallets}
              onNewWallet={handleNewWallet}
              onDelete={handleDeleteWallet}
              onNewName={handleNewNameWallet}
              syncing={syncing || !isAuthenticated}
              currency={currency}
              mainWallet={user?.get('ethAddress')?.toLowerCase() ?? ''}
            />
          </PageStyledPaper>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default DashboardPage;
