import { FC, useState } from 'react';
import { ENVIRONMENT } from '../Utils/constants';
import { Button, Paper, Slide, useMediaQuery } from '@mui/material';
import { styled, css, useTheme } from '@mui/system';
import Logo from '../images/quanty_logo.svg';
import HomeIcon from '@mui/icons-material/Home';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import BarChartIcon from '@mui/icons-material/BarChart';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Link } from 'react-router-dom';
import { ROUTES } from '../config/routes';

const Root = styled(Paper)<{ matches: boolean }>`
  width: 240px;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: white;
  border-radius: 0px;
  position: relative;

  ${({ matches }) =>
    matches &&
    css`
      display: none;
    `}
`;

const ItemContainer = styled('div')`
  width: 200px;
  height: 100%;
`;

const Items = styled('div')`
  width: 180px;
  position: sticky;
  top: 10px;
`;

const NavButton = styled(Button)<{ active?: boolean }>`
  padding: 12px;
  color: #9ca3af;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;

  .MuiButton-startIcon {
    margin-right: 18px;
  }

  ${({ active }) =>
    active &&
    css`
      color: white;
      background-color: #111827;
      border-radius: 6px;
    `}
`;

const NavbarLogo = styled('img')`
  margin-bottom: 18px;
  width: 200px;
`;

export const Header: FC = () => {
  const [active, setActive] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const navbarItems = [
    {
      link: ROUTES.HOME,
      icon: <HomeIcon />,
      text: 'Dashboard',
    },
    {
      link: ROUTES.ANALYSE,
      icon: <DataUsageIcon />,
      text: 'Analyse',
    },
    {
      link: ROUTES.MARKETS,
      icon: <BarChartIcon />,
      text: 'Markets',
    },
    {
      link: ROUTES.PRICES,
      icon: <PriceChangeIcon />,
      text: 'Prices',
    },
    {
      link: ROUTES.NEWS,
      icon: <AutoStoriesIcon />,
      text: 'News',
    },
  ];

  return (
    <Slide direction="right" in={!matches} mountOnEnter>
      <Root elevation={4} matches={matches}>
        <ItemContainer>
          <p style={{ color: '#7c7c7c' }}> {ENVIRONMENT.toUpperCase()}</p>
          <Items>
            <NavbarLogo src={Logo} alt="NFTTRUST LOGO" />
            {navbarItems.map((item, index) => (
              <Link to={item.link} style={{ textDecoration: 'none' }}>
                <NavButton
                  key={index}
                  active={index === active ? true : false}
                  onClick={() => setActive(index)}
                  startIcon={item.icon}
                >
                  {item.text}
                </NavButton>
              </Link>
            ))}
          </Items>
        </ItemContainer>
      </Root>
    </Slide>
  );
};
