import React, { FC, useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ModalRoot } from '../Reusable/styledComponents';
import { BaseModalProps } from './Base/BaseModalProps';

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

const AddShareModal: FC<BaseModalProps> = ({ open, handleClose, handleOk }) => {
  const [value, setValue] = useState<number>(0);

  const save = () => {
    handleOk(value);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalRoot>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography>Add Share Value</Typography>
          <StyledTextField
            type="number"
            fullWidth
            label="Add Share value"
            onChange={(e) => setValue(+e.target.value)}
          />
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save}>Ok</Button>
        </Grid>
      </ModalRoot>
    </Modal>
  );
};

export default AddShareModal;
