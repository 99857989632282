import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './config/routes';
import AnalysePage from './Pages/AnalysePage';
import DashboardPage from './Pages/DashboardPage';
import LoginPage from './Pages/LoginPage';
import MarketPage from './Pages/MarketPage';
import NewsPage from './Pages/NewsPage';
import PricesPage from './Pages/PricesPage';
import PrivateRoute from './PrivateRoute';

const AppRouting: React.FC = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.ANALYSE}
        element={
          <PrivateRoute>
            <AnalysePage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.MARKETS}
        element={
          <PrivateRoute>
            <MarketPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.PRICES}
        element={
          <PrivateRoute>
            <PricesPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.NEWS}
        element={
          <PrivateRoute>
            <NewsPage />
          </PrivateRoute>
        }
      />
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
    </Routes>
  );
};

export default AppRouting;
