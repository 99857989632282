import { FC, useState } from 'react';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { ModalRoot, StyledTextField } from '../Reusable/styledComponents';
import { BaseModalProps } from './Base/BaseModalProps';

const AddCashPositionModal: FC<BaseModalProps> = ({ open, handleClose, handleOk }) => {
  const [value, setValue] = useState<number>(0);

  const save = () => {
    handleOk(value);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalRoot>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography>Add Cashposition value</Typography>
          <StyledTextField
            type="number"
            fullWidth
            label="Cashposition value"
            onChange={(e) => setValue(+e.target.value)}
          />
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save}>Ok</Button>
        </Grid>
      </ModalRoot>
    </Modal>
  );
};

export default AddCashPositionModal;

//todo:
