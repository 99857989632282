import { styled } from '@mui/system';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const COLORS = ['#003953', '#1A4185', '#A13692', '#F41270'];

const ToolTipRoot = styled('div')`
  background-color: white;
  padding: 12px;
  border: 1px solid #e5e7eb;
`;

interface PieChartDashboardProps {
  stableCoinsPosition: number;
  cryptoPosition: number;
  nftPosition: number;
}

const PieChartDashboard: React.FC<PieChartDashboardProps> = ({ stableCoinsPosition, cryptoPosition, nftPosition }) => {
  const data = [
    { name: 'Stables', value: stableCoinsPosition },
    { name: 'Crypto', value: cryptoPosition },
    { name: 'NFT', value: nftPosition },
  ];

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload) {
      return (
        <ToolTipRoot>
          <p>{`${payload[0].name} : ${payload[0].value}€`}</p>
        </ToolTipRoot>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width={'99%'} height={400}>
      <PieChart>
        <Pie
          data={data}
          cx={175}
          cy={175}
          innerRadius={100}
          outerRadius={130}
          fill="#8884d8"
          paddingAngle={1}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip cursor={true} content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartDashboard;
