import { NftPriceInfo } from '../Types/Collections/NFTPriceInfo';
import { OpenseaData } from '../Types/Collections/OpenSeaData';
import { PriceAtDate } from '../Types/Collections/PriceAtDate';
import { OpenseaDto } from '../Types/OpenseaDto';
import {
  DEFAULT_DATE,
  FIAT_DIGEST,
  IPFS_GATEWAY,
  IPFS_PROTOCOL,
  OPENSEA_STORE_CONTRACT as OPENSEA_STORE_CONTRACT,
  UsdToEur,
} from './constants';

export function uiDate(date: Date, withDetails: boolean = false) {
  let format = [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('.');

  if (withDetails) {
    let details = [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':');

    return format + ' ' + details;
  }

  return format;
}

function padTo2Digits(num: any) {
  return num.toString().padStart(2, '0');
}

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByCondition(xs: any, key: any, parser: any) {
  return xs.reduce(function (rv: any, x: any) {
    const objectKey = x[key];
    const parsedKey = parser(objectKey);
    (rv[parsedKey] = rv[parsedKey] || []).push(x);
    return rv;
  }, {});
}

//ipfs://QmWfu9d4KQp8nb5Cb2Ha3Ju1kYTEG7xCPA4RrvH5GQhXnP
export function castIpfsUrl(imageUrl: string) {
  const url = imageUrl?.startsWith(IPFS_PROTOCOL) ? imageUrl.replace(IPFS_PROTOCOL, IPFS_GATEWAY) : imageUrl;
  if (url && url.includes('/ipfs/ipfs')) {
    return url.replace('/ipfs/ipfs', '/ipfs');
  }
  return url;
}

export function getImageUrl(nft: any) {
  let url = JSON.parse(nft.metadata)?.image;
  return castIpfsUrl(url);
}
export function distinct(array: any[], key: string = 'address') {
  return [...new Map(array.map((v: any) => [v[key], v])).values()];
}

export function toDictionaryOneToOne(array: any[], key: string) {
  var addressToPriceInfo: any = {};
  array.forEach((el: any) => {
    addressToPriceInfo[el[key]] = el;
  });
  return addressToPriceInfo;
}

export function toDictionaryOneToMany(array: any[], key: string) {
  var addressToPriceInfo: any = {};
  array.forEach((el: any) => {
    if (addressToPriceInfo[el[key]]) {
      addressToPriceInfo[el[key]].push(el);
    } else {
      addressToPriceInfo[el[key]] = [el];
    }
  });
  return addressToPriceInfo;
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(dateA: Date, dateB: Date = new Date()) {
  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateDiffInHours(dateA: Date, dateB: Date = new Date()) {
  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate(), dateA.getHours());
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate(), dateB.getHours());
  return Math.floor(((utc2 - utc1) / _MS_PER_DAY) * 24);
}

export function suma(array: any[], key: string | null = null) {
  let totalEth = 0;
  array.forEach((item) => {
    totalEth += key ? +item[key] : +item;
  });
  return totalEth;
}

export function formatNumber(price: number, digits: number = 2): string {
  return Number(price).toLocaleString('es-ES', {
    minimumFractionDigits: digits,
  });
}

export function calculateEurValueStringFormat(tokenPrice: number, tokenValue: number): string {
  const eurValue = +(+tokenValue * tokenPrice * UsdToEur);
  return formatNumber(+eurValue.toFixed(FIAT_DIGEST));
}

export function calculateEurValueNumber(tokenPrice: number, tokenValue: number): number {
  const eurValue = +(+tokenValue * tokenPrice * UsdToEur).toFixed(FIAT_DIGEST);
  return eurValue;
}

export function openseaDataForToken(
  floorPrices: any,
  address: string,
  tokenId: string | null = null,
): OpenseaTokenInfo {
  const defaultPrice = { date: DEFAULT_DATE, price: 0 } as PriceAtDate;

  if (floorPrices && floorPrices[address]) {
    const nftsData: OpenseaData[] = floorPrices[address];
    if (nftsData.length > 0) {
      const nftData =
        address == OPENSEA_STORE_CONTRACT && tokenId
          ? nftsData.find((c) => c.tokenId.slice(0, 4) === tokenId.slice(0, 4))
          : nftsData[0];
      const floorPriceNative = nftData?.floorPrices.at(-1) ?? defaultPrice;
      return { floorPrice: floorPriceNative, name: nftData?.name ?? null };
    }
  }
  return { floorPrice: defaultPrice, name: null };
}

export function replaceByValue(array: NftPriceInfo[], element: NftPriceInfo) {
  if (!array) return [element];
  const index = array.findIndex((c) => c.tokenId === element.tokenId && c.walletAddress === element.walletAddress);
  if (index !== -1) {
    array[index] = element;
  }
  return array;
}

export function getTokenAddresses(
  walletsData: any,
  floorPrices: any,
  onlyMissed: boolean = true,
  distincted: boolean = false,
): OpenseaDto[] {
  const tokenContracts = Object.keys(walletsData)
    .map((key) => {
      const chain = walletsData[key].address.chain;

      return walletsData[key].data.nfts.map((element: any) => {
        const address = element.token_address;
        if (address === OPENSEA_STORE_CONTRACT) {
        }
        const floorPriceInfo: OpenseaData | undefined = floorPrices[address];

        const shouldSkip = (floorPriceInfo === undefined || floorPriceInfo?.isNftHasData === true) && onlyMissed;
        const isOpenseaHasDataForNft = floorPriceInfo ? floorPriceInfo.isNftHasData : undefined;

        if (!shouldSkip && isOpenseaHasDataForNft !== false)
          return { chain: chain, tokenAddress: address, tokenId: element.token_id } as OpenseaDto;
      });
    })
    .flatMap((nfts) => nfts)
    .filter((nft) => nft !== undefined);
  if (distincted) {
    return distinct(tokenContracts, 'tokenAddress');
  } else return tokenContracts;
}

export function chunking(inputArray: any[], size: number) {
  return inputArray.reduce((resultArray: any[], item: any, index: number) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

interface OpenseaTokenInfo {
  floorPrice: PriceAtDate;
  name: string | null;
}

const evaluationInner = (automaticDetectedBoughtPrice: number, lastFloorNative: any, tokenPriceInfo: NftPriceInfo) => {
  const formula = (floorToday: number, floorAc: number, ac: number) => {
    return (floorToday * (100 / floorAc) * ac) / 100;
  };

  let result = 0;

  const isStaticPriceExist = !!tokenPriceInfo?.staticPriceNative && tokenPriceInfo?.staticPriceNative !== 0;
  const isAllDataExist =
    !!tokenPriceInfo?.floorAcNative &&
    !!lastFloorNative &&
    (!!tokenPriceInfo?.acNative || !!automaticDetectedBoughtPrice);
  const isAcFloorExist = !!tokenPriceInfo?.floorAcNative;
  const isAcExist = !!tokenPriceInfo?.acNative;

  if (isStaticPriceExist) result = tokenPriceInfo.staticPriceNative;
  else if (!isAllDataExist && lastFloorNative !== 0) {
    result = lastFloorNative;
  } else if (isAcExist && !isAllDataExist) {
    result = tokenPriceInfo?.acNative;
  } else if (isAcFloorExist && !isAllDataExist) {
    result = tokenPriceInfo?.floorAcNative;
  } else if (isAllDataExist && !isStaticPriceExist)
    result = formula(
      lastFloorNative ?? 1,
      tokenPriceInfo?.floorAcNative ?? 1,
      automaticDetectedBoughtPrice ? automaticDetectedBoughtPrice : tokenPriceInfo?.acNative ?? 1,
    );
  return result;
};

export function calcEvaluationNative(
  tokenPriceInfo: NftPriceInfo,
  lastFloor: any,
  automaticDetectedBoughtPrice: number,
) {
  const evaluationByAcOrFloor = lastFloor ? lastFloor : automaticDetectedBoughtPrice;
  const evaluation = {
    evaluationValue: evaluationByAcOrFloor,
    floorPriceAc: lastFloor,
    isEdited: false,
    chain: 'eth',
  };

  if (!tokenPriceInfo) return evaluation;

  evaluation.floorPriceAc = tokenPriceInfo.floorAcNative;

  const evaluationValue = evaluationInner(automaticDetectedBoughtPrice, lastFloor, tokenPriceInfo);

  evaluation.evaluationValue = evaluationValue ? evaluationValue : evaluationByAcOrFloor;
  evaluation.isEdited =
    !!tokenPriceInfo.acNative || !!tokenPriceInfo.staticPriceNative || !!tokenPriceInfo.floorAcNative;

  return evaluation;
}
export function usdValue(tokenPrice: number, tokenValue: number): number {
  return +(+tokenValue * tokenPrice).toFixed(FIAT_DIGEST);
}
