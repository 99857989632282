import { Box, Grid, Paper, styled, TextField } from '@mui/material';

export const PageStyledPaper = styled(Paper)`
  min-height: 450px;
  width: 100%;
  padding: 24px;
`;

export const PageHeadline = styled('h1')`
  padding-left: 16px;
  width: 100%;
  font-size: 40px;
  color: white;
`;

export const GreyText = styled('div')`
  font-size: 16px;
  font-weight: 500;
  color: #9ca3af;
  margin-bottom: 4px;
`;

export const GreyTextBold = styled(GreyText)`
  font-weight: 700;
`;

export const Title = styled('div')(({ theme }) => ({
  fontSize: '48px',
  fontWeight: 600,
  color: theme.palette.primary.dark,
}));

export const StatsContainerRoot = styled(Grid)`
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 24px;
`;

export const StyledWalletBar = styled('div')`
  color: #9ca3af;
`;

export const StyledListGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Hairline = styled('div')`
  background-color: #e5e7eb;
  width: 1px;
`;

export const ModalRoot = styled(Box)`
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background-color: white;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
  margin-top: 16px;
`;
