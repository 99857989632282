import { FC } from 'react';

import { Grid } from '@mui/material';

import PageWrapper from '../Components/PageWrapper';
import { PageHeadline, PageStyledPaper } from '../Components/Reusable/styledComponents';

const MarketPage: FC = () => {
  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <PageHeadline>Market</PageHeadline>
        <Grid item xs={12} md={12}>
          <PageStyledPaper>Detail Market Page coming soon..</PageStyledPaper>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sx={{ marginTop: '32px' }}>
        <PageStyledPaper>Detail Market Page coming soon..</PageStyledPaper>
      </Grid>
    </PageWrapper>
  );
};

export default MarketPage;
