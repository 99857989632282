import React, { FC, useState } from 'react';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridColDef,
  MuiEvent,
} from '@mui/x-data-grid';
import { ISubwallet, IWalletNftGridItem } from '../../Types/Grid/ISubwallet';
import {
  AvatarGroup,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import NFTAvatar from '../NFTAvatar';
import { formatNumber, uiDate } from '../../Utils/utils';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import { CircularProgressWithLabel } from '../Reusable/CircularProgressWithLabel';

type NftAssetsProps = {
  rows: ISubwallet[];
  syncing: boolean;
  currency: string;
  mainWallet: string;
  onNewWallet: (walletAddress: string, walletName: string) => Promise<void>;
  onDelete: (walletAddress: string) => Promise<void>;
  onNewName: (walletName: string, walletAddress: string) => Promise<void>;
};
const WalletsInfo: FC<any> = ({
  rows,
  currency,
  syncing,
  mainWallet,
  onNewWallet,
  onDelete,
  onNewName,
}: NftAssetsProps) => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      align: 'center',
      renderCell: (params) => {
        const completed = params.row.progress === 100;
        const lastSyncronization = params.row?.lastSyncDate ?? null;
        const lastActivityDate = params.row?.lastWalletActivityDate ?? null;

        const lastActivityData = lastActivityDate ? 'Last activity at ' + uiDate(lastActivityDate, true) : 'N/A';
        const lastSyncData = lastSyncronization
          ? 'Analysed at ' + uiDate(lastSyncronization, true)
          : 'In progress' + params.row.progress;

        return (
          <>
            <Tooltip title={lastSyncData + '\n' + lastActivityData}>
              {completed ? (
                <DoneIcon sx={{ color: 'green' }}></DoneIcon>
              ) : (
                <CircularProgressWithLabel value={params.row.progress}></CircularProgressWithLabel>
              )}
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'walletName',
      headerName: 'Wallet Name',
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.walletName}</span>
          </div>
        );
      },
      valueGetter: (params) => params.row.walletName,
      valueSetter: (params: any) => {
        const newValue = params.value;
        return newValue;
      },
    },
    {
      field: 'walletId',
      headerName: 'Address',
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={'Copy'}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(params.row.walletId);
                }}
              >
                <span>
                  {params.row.walletId.slice(0, 5)}...{params.row.walletId.slice(-5)}
                </span>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'chain',
      headerName: 'Chain',
      width: 80,
      renderCell: (params) => {
        return <span>{params.row.chain}</span>;
      },
    },
    {
      field: 'nfts',
      headerName: 'Newest NFTS',
      width: 230,
      renderCell: (params) => {
        const walletStatus = isSyncing(params);
        return (
          <>
            {!walletStatus ? (
              <div>
                <AvatarGroup total={params.row?.nfts?.length ?? 0}>
                  {params.row.nfts.map((item: IWalletNftGridItem, index: number) => (
                    <NFTAvatar key={`${item.image}-${index}}`} imageUrl={item.image} altText={item.name} />
                  ))}
                </AvatarGroup>
              </div>
            ) : (
              <>
                <Skeleton variant="rectangular" width={210} height={50} />
              </>
            )}
          </>
        );
      },
      valueGetter: (params: any) => params.row.nfts.length,
    },
    {
      field: 'count',
      headerName: 'Unique/Total',
      width: 130,
      renderCell: (params) => {
        const walletStatus = isSyncing(params);
        return (
          <>
            {!walletStatus ? (
              <div>
                {params.row?.nftsUniqueCount ?? 0}/{params.row?.nftsTotalCount ?? 0}
              </div>
            ) : (
              <Skeleton variant="rectangular" width={210} height={50} />
            )}
          </>
        );
      },
      valueGetter: (params: any) => params.row.nftsTotalCount,
    },
    {
      field: 'nativeValue',
      headerName: 'ETH Coins',
      width: 130,
      renderCell: (params) => {
        const walletStatus = isSyncing(params);
        return (
          <>
            {!walletStatus ? (
              <div>{formatNumber(params.row?.nativeValue ?? 0)}</div>
            ) : (
              <Skeleton variant="rectangular" width={210} height={50} />
            )}
          </>
        );
      },
    },
    {
      field: 'wrappedNativeValue',
      headerName: 'WETH Coins',
      width: 130,
      renderCell: (params) => {
        const walletStatus = isSyncing(params);
        return (
          <>
            {!walletStatus ? (
              <div>{formatNumber(params.row?.wrappedNativeValue ?? 0)}</div>
            ) : (
              <Skeleton variant="rectangular" width={210} height={50} />
            )}
          </>
        );
      },
    },
    {
      field: 'usdValue',
      headerName: 'Fiat Value total',
      width: 140,
      renderCell: (params) => {
        const walletStatus = isSyncing(params);
        return (
          <>
            {!walletStatus ? (
              <div>
                {formatNumber(params.row?.usdValue ?? 0)} {currency}
              </div>
            ) : (
              <Skeleton variant="rectangular" width={210} height={50} />
            )}
          </>
        );
      },
    },
    {
      field: 'coinsCount',
      headerName: 'Coins count',
      width: 140,
      renderCell: (params) => {
        const walletStatus = isSyncing(params);
        return (
          <>
            {!walletStatus ? (
              <div>{params.row?.coinsCount ?? 0}</div>
            ) : (
              <Skeleton variant="rectangular" width={210} height={50} />
            )}
          </>
        );
      },
    },
    {
      field: 'Interaction',
      headerName: 'Interaction',
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              disabled={mainWallet === params.row.walletId}
              onClick={() => {
                onDelete(params.row.walletId);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const isSyncing = (params: any) => (params.row.syncing === true ? true : false);
  const processRowUpdate = async (newName: any, wallet: any) => {
    await onNewName(newName, wallet.walletId);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [newWalletAddress, setNewWalletAddress] = React.useState('');
  const [newWalletName, setNewWalletName] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewWalletAddress('');
    setNewWalletName('');
  };

  const onNewWalletInner = async () => {
    handleClose();
    await onNewWallet(newWalletAddress, newWalletName);
    setNewWalletAddress('');
    setNewWalletName('');
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ marginBottom: '24px' }}>
        <Button disabled={!mainWallet} onClick={handleOpen} variant="outlined" color="primary">
          Add new wallet
        </Button>
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add subwallet
            </Typography>
            <Typography id="modal-modal-title" variant="body2" component="h2">
              Currently supporting ETH Chain only - more coming soon
            </Typography>
            <TextField
              label="Wallet (ETH) Address"
              color="secondary"
              onChange={(e) => setNewWalletAddress(e.target.value)}
              required
            />
            <TextField label="Name" color="secondary" onChange={(e) => setNewWalletName(e.target.value)} required />
            <Stack direction="row" justifyContent="space-between">
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={() => onNewWalletInner()}>Add</Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Grid
        style={{ height: 400, width: '100%' }}
        sx={{
          '& .main-wallet': {
            backgroundColor: 'custom.mainWallet',
          },
          '& .main-wallet:hover': {
            backgroundColor: 'background.mainWalletHover',
          },
        }}
      >
        {!syncing ? (
          <DataGrid
            getRowId={(row) => row.walletId + row.chain}
            rows={[...rows]}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 50, 100]}
            pagination
            experimentalFeatures={{ newEditingApi: true }}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
              console.log('stop', params);
              params.cellMode = 'view';
              event.defaultMuiPrevented = false;
              if (
                params.reason === GridCellEditStopReasons.cellFocusOut ||
                params.reason === GridCellEditStopReasons.enterKeyDown
              ) {
                // event.defaultMuiPrevented = true;
              }
            }}
            processRowUpdate={processRowUpdate}
            onCellEditCommit={(params: GridCellEditCommitParams, event: MuiEvent) => {
              event.defaultMuiPrevented = false;
              console.log('commit', params);
              if (params) {
                event.defaultMuiPrevented = true;
              }
            }}
            getRowClassName={(params: any) => {
              return mainWallet === params.row.walletId ? 'main-wallet' : '';
            }}
            loading={syncing}
          />
        ) : (
          <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
        )}
      </Grid>
    </>
  );
};

export default WalletsInfo;
