import React, { FC, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TransferType } from '../../Types/TransferType';
import { uiDate } from '../../Utils/utils';
import { In, Out } from './Transfers';
import { DEFAULT_PAGE_SIZE, ETHERSCAN_TOKEN_HASH_LINK } from '../../Utils/constants';
import Transaction from '../../images/Icons/transaction.png';
import { Skeleton } from '@mui/material';
import { ITransferGridItem } from '../../Types/Grid/ITransferGridItem';

const NonFungibleTransfers: FC<{ rows: ITransferGridItem[]; syncing: boolean }> = ({ rows, syncing }) => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const columns = [
    {
      field: 'event',
      headerName: 'Event',
      width: 50,
      renderCell: (params: any) => {
        return <></>;
      },
    },
    {
      field: 'from',
      headerName: 'From',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <p>{params.row.from.slice(0, 6)}</p>
          </>
        );
      },
    },
    {
      field: 'to',
      headerName: 'To',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <p>{params.row.to.slice(0, 6)}</p>
            <p></p>
          </>
        );
      },
    },
    { field: 'chain', headerName: 'Chain', width: 70 },

    {
      field: 'token',
      headerName: 'Token',
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.token.tokenAddress.slice(0, 6)}/{params.row.token.tokenId.slice(0, 6)}
          </>
        );
      },
    },
    {
      field: 'what',
      headerName: 'What',
      width: 75,
      renderCell: (params: any) => {
        return <>{params.row.type === TransferType.IN ? <In>{params.row.type}</In> : <Out>{params.row.type}</Out>}</>;
      },
    },
    { field: 'amount', headerName: 'Quantity', width: 75 },
    // { field: "nativeValue", headerName: "ETH Floor", width: 125 },
    // { field: "eurValue", headerName: "EUR", width: 125 },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      type: 'Date',
      renderCell: (params: any) => {
        return <>{uiDate(params.row.date, true)}</>;
      },
    },
    {
      field: 'hash',
      headerName: 'TxHash',
      width: 125,
      renderCell: (params: any) => {
        return (
          <>
            <span>
              <a href={ETHERSCAN_TOKEN_HASH_LINK + params.row.hash} target="_blank" rel="noreferrer">
                <img src={Transaction} alt="ETH LINK" style={{ width: '24px', height: '24px', marginRight: '12px' }} />
              </a>
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {}, [rows]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      {!syncing ? (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'desc' }],
            },
          }}
          getRowId={(row) => row.id}
          rows={[...rows]}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 50, 100]}
          pagination
        />
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
      )}
    </div>
  );
};
export default NonFungibleTransfers;
