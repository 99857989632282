export enum ROUTES {
  HOME = '/',
  ANALYSE = '/analyse',
  MARKETS = '/markets',
  PRICES = '/prices',
  NEWS = '/news',
  LOGIN = '/login',
}

export const PageTitles: Record<ROUTES, string> = {
  [ROUTES.HOME]: 'Dashboard',
  [ROUTES.ANALYSE]: 'Wallet Detail Analyse',
  [ROUTES.MARKETS]: 'Current Markets',
  [ROUTES.PRICES]: 'Current Prices',
  [ROUTES.NEWS]: 'News',
  [ROUTES.LOGIN]: 'Login',
};
