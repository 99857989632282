import * as React from 'react';
import { Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { castIpfsUrl } from '../Utils/utils';

const PlaceHolderAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

interface NFTAvatarProps {
  imageUrl: string;
  altText?: string;
  width?: string;
  height?: string;
}

const NFTAvatar: React.FC<NFTAvatarProps> = ({
  imageUrl,
  altText = 'nftrust image',
  width = '40px',
  height = '40px',
}) => {
  const imageLink = castIpfsUrl(imageUrl);

  const MemoAvatar = React.useMemo(
    () => (
      <Avatar
        src={imageLink}
        alt={altText}
        style={{
          height: `${height}`,
          width: `${width}`,
          borderRadius: '50%',
        }}
      />
    ),
    [imageLink],
  );

  const isNotVideo = imageLink?.split('.').pop() !== 'mp4';
  const isNotUnknown = imageLink?.split('/').pop() !== 'unknown.png';
  return imageLink && isNotVideo && isNotUnknown ? (
    <>{MemoAvatar}</>
  ) : (
    <>
      <PlaceHolderAvatar
        style={{
          height: `${height}`,
          width: `${width}`,
          borderRadius: '50%',
        }}
      />
      {imageLink}
    </>
  );
};

export default NFTAvatar;
