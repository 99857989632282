import { FC, useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridFilterItem } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { INFTAssetGrid } from '../../Types/Grid/INFTAssetGrid';
import { formatNumber, uiDate } from '../../Utils/utils';
import { Button, Grid, IconButton, InputAdornment, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
  CHAIN_TO_CURRENCY,
  DEFAULT_PAGE_SIZE,
  ETHERSCAN_ADRESS_LINK,
  ETHERSCAN_TOKEN_HASH_LINK,
  ETH_DIGEST,
  FIAT_DIGEST,
  OPENSEA_NFT_ASSET_LINK,
} from '../../Utils/constants';
import NFTEditModal from '../Modals/NFTEditModal';
import NFTAvatar from '../NFTAvatar';
import EtheriumIcon from '../../images/Icons/etherscan-logo-circle.svg';
import OpenSeaIcon from '../../images/Icons/openSeaIcon.svg';
import Transaction from '../../images/Icons/transaction.png';
import { NftPriceInfo } from '../../Types/Collections/NFTPriceInfo';
import { Search } from '@mui/icons-material';

type NftAssetsProps = {
  rows: INFTAssetGrid[];
  onNewNftDetails: any;
  currency: string;
  ethPrice: number;
  syncing: boolean;
};

const NftAssets: FC<any> = ({ rows, onNewNftDetails, ethPrice, currency, syncing }: NftAssetsProps) => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [filt, setFilt] = useState<GridFilterItem[]>([]);
  const [hideLowPerformaer, setHideLowPerformer] = useState(false);
  const [currentData, setCurrentData] = useState(rows);

  const EVAL_CC_HIDE = 0.0001;

  useEffect(() => {
    setCurrentData(rows);
    if (hideLowPerformaer) {
      setCurrentData(rows.filter((e) => e.evaluationNative >= EVAL_CC_HIDE));
    }
    if (!hideLowPerformaer) {
      setCurrentData(rows);
    }
  }, [hideLowPerformaer, syncing]);

  const modalDefaultState = {
    open: false,
    id: '',
    tokenId: '',
    walletId: '',
    name: '',
    imageUrl: '',
    detectedAc: 0,
    tokenPriceInfo: null,
  };

  const [openEdit, setOpenEdit] = useState<{
    open: boolean;
    id: string;
    tokenId: string;
    walletId: string;
    name: string;
    imageUrl: string;
    detectedAc: number;
    tokenPriceInfo: NftPriceInfo | null;
  }>(modalDefaultState);

  const ProjectInfo = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  `;
  const PlusSpan = styled('p')`
    color: green; //use from theme
  `;
  const MinusSpan = styled('p')`
    color: red;
  `;

  const getTickerByChain = (chain: string): string => CHAIN_TO_CURRENCY[chain];

  const columns: GridColDef[] = [
    {
      field: 'info',
      headerName: 'Project Name',
      width: 300,
      type: 'string',
      renderCell: ({ row }) => {
        const { tokenId } = row;
        return (
          <>
            <ProjectInfo>
              <NFTAvatar imageUrl={row?.metadata?.image} width="30px" height="30px" />
              <span>{row?.metadata?.name}</span>
              <span>{tokenId.slice(0, 6)} </span>
            </ProjectInfo>
          </>
        );
      },
      valueGetter: (params) => params.row.metadata.name,
    },
    {
      field: 'tokenId',
      headerName: 'Toolbar',
      width: 200,
      renderCell: (params) => {
        const { tokenAddress, projectName, tokenId, automaticDetectedPriceInNativeToken, walletId, tokenPriceInfo } =
          params.row;
        return (
          <Grid container justifyContent="flex-end" direction="row" alignContent="center">
            <Tooltip title={'Edit NFT'}>
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  setOpenEdit({
                    open: true,
                    id: tokenAddress,
                    name: projectName,
                    tokenId: tokenId,
                    walletId: walletId,
                    imageUrl: params.row?.metadata.image,
                    detectedAc: automaticDetectedPriceInNativeToken,
                    tokenPriceInfo: tokenPriceInfo,
                  })
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Opensea'}>
              <a
                href={`${OPENSEA_NFT_ASSET_LINK}${params.row.tokenAddress}/${params.row.tokenId}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={OpenSeaIcon} alt="ETH LINK" style={{ width: '24px', height: '24px', marginRight: '12px' }} />
              </a>
            </Tooltip>
            <Tooltip title={'Contract'}>
              <span>
                <a href={ETHERSCAN_ADRESS_LINK + params.row.tokenAddress} target="_blank" rel="noreferrer">
                  <img
                    src={EtheriumIcon}
                    alt="ETH LINK"
                    style={{ width: '24px', height: '24px', marginRight: '12px' }}
                  />
                </a>
              </span>
            </Tooltip>
            <Tooltip title={'Transaction'}>
              <span>
                <a href={ETHERSCAN_TOKEN_HASH_LINK + params.row.hash} target="_blank" rel="noreferrer">
                  <img
                    src={Transaction}
                    alt="ETH LINK"
                    style={{ width: '24px', height: '24px', marginRight: '12px' }}
                  />
                </a>
              </span>
            </Tooltip>
          </Grid>
        );
      },
    },

    {
      field: 'lastFloorPriceNative',
      headerName: 'FP(NOW) CC',
      width: 130,
      renderCell: (params) => {
        return (
          <Tooltip title={'Last updated:' + uiDate(params.row.lastFloorPriceNative.date, true)}>
            <span>
              {params.row.lastFloorPriceNative?.price === 0 ? (
                '-'
              ) : params.row.lastFloorPriceNative?.price < 0.00001 ? (
                '<0.00001'
              ) : (
                <span>{+params.row.lastFloorPriceNative.price.toFixed(ETH_DIGEST)}</span>
              )}
            </span>
          </Tooltip>
        );
      },
      valueGetter: (params) => params.row.lastFloorPriceNative.price,
    },
    {
      field: 'floorPriceAc',
      headerName: 'FP(AC) CC',
      width: 130,
      renderCell: (params) => {
        return (
          <span>
            {params.row.floorPriceAc === 0 ? '-' : params.row.floorPriceAc + ' ' + getTickerByChain(params.row.chain)}
          </span>
        );
      },
      valueGetter: (params) => params.row.floorPriceAc,
    },
    {
      field: 'boughtPriceInNativeToken',
      headerName: 'AC In Coin',
      width: 130,
      renderCell: (params) => {
        return <span>{params.row.boughtPriceInNativeToken + ' ' + getTickerByChain(params.row.chain)}</span>;
      },
    },
    {
      field: 'boughtAt',
      headerName: 'BoughtAt',
      width: 130,
      type: 'Date',
      renderCell: (params) => {
        return <span>{uiDate(params.row.boughtAt)}</span>;
      },
    },
    {
      field: 'spentUsd',
      headerName: `AC ${currency}`,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={formatNumber(params.row.usdPriceAtDate) + `${currency}/${getTickerByChain(params.row.chain)}`}
            >
              <span>
                {formatNumber(params.row.spentUsd)}
                {currency}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'gainInNative',
      headerName: 'Profit AC',
      width: 150,
      renderCell: (params) => {
        const { gainInNative, gainInPercentsByAc } = params.row;

        return (
          <>
            {gainInNative > 0 ? <PlusSpan>{gainInNative}</PlusSpan> : <MinusSpan>{gainInNative}</MinusSpan>}/
            {gainInPercentsByAc > 0 ? (
              <PlusSpan>{gainInPercentsByAc ?? '-'}%</PlusSpan>
            ) : (
              <MinusSpan>{gainInPercentsByAc ?? '-'}%</MinusSpan>
            )}
          </>
        );
      },
      valueGetter: (params) => params.row.gainInPercentsByAc,
    },
    {
      field: 'gainInFiat',
      headerName: `Profit ${currency}`,
      width: 150,
      renderCell: (params) => {
        const gainFiat = formatNumber(params.row.gainInFiat);
        return (
          <>
            {params.row.gainInFiat > 0 ? <PlusSpan>{gainFiat}</PlusSpan> : <MinusSpan>{gainFiat}</MinusSpan>}/
            {params.row.gainInPercentsByFiat > 0 ? (
              <PlusSpan>{params.row.gainInPercentsByFiat ?? '-'}%</PlusSpan>
            ) : (
              <MinusSpan>{params.row.gainInPercentsByFiat ?? '-'}%</MinusSpan>
            )}
          </>
        );
      },
      valueGetter: (params) => params.row.gainInPercentsByFiat,
    },
    {
      field: 'evaluationNative',
      headerName: 'EVAL CC',
      width: 130,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: +params.row.evaluationNative <= 0 ? 'red' : 'black',
            }}
          >
            {params.row.evaluationNative + ' ' + getTickerByChain(params.row.chain)}
          </span>
        );
      },
    },
    {
      field: 'evaluationFiat',
      headerName: `EVAL ${currency}`,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={formatNumber(ethPrice, FIAT_DIGEST) + `${currency}/${getTickerByChain(params.row.chain)}`}>
              <span>
                {formatNumber(params.row.evaluationFiat)}
                {currency}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'isEdited',
      headerName: 'IsEdited',
      width: 130,
      renderCell: ({ row }) => {
        const { isEdited } = row;
        return <span>{isEdited ? <DoneIcon /> : <CloseIcon />}</span>;
      },
      valueGetter: (params) => params.row?.isEdited,
    },
    {
      field: 'isStaticPrice',
      headerName: 'Static Price',
      width: 130,
      renderCell: ({ row }) => {
        const { tokenPriceInfo } = row;
        return <span>{tokenPriceInfo?.staticPriceNative ? <DoneIcon /> : <CloseIcon />}</span>;
      },
      valueGetter: (params) => params.row.tokenPriceInfo?.staticPriceNative,
    },
    { field: 'amount', headerName: 'NFT count', width: 70, type: 'number' },
    {
      field: 'chain',
      headerName: 'Chain',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.chain.toUpperCase()}</span>;
      },
      valueGetter: (params) => params.row.chain,
    },
    {
      field: 'ids',
      headerName: 'Wallet Name',
      width: 130,
      renderCell: (params) => {
        return <span>{params.row.walletName}</span>;
      },
      valueGetter: (params) => params.row.walletName,
    },
  ];

  return (
    <>
      <NFTEditModal
        open={openEdit.open}
        handleClose={() => setOpenEdit(modalDefaultState)}
        handleOk={(e, shouldUpdate) => {
          onNewNftDetails(e, shouldUpdate);
        }}
        name={openEdit.name}
        id={openEdit.id}
        tokenId={openEdit.tokenId}
        walletId={openEdit.walletId}
        imageUrl={openEdit.imageUrl}
        detectedAc={openEdit.detectedAc}
        tokenPriceInfo={openEdit.tokenPriceInfo}
      />

      <Grid container direction="column" justifyContent="flex-start">
        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ marginBottom: '12px' }}>
          <TextField
            onChange={(e) =>
              setFilt([
                {
                  id: 1,
                  columnField: 'info',
                  operatorValue: 'contains',
                  value: e.target.value,
                },
              ])
            }
            size="small"
            placeholder="Search for NFT"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="outlined" color="error" onClick={() => setHideLowPerformer(!hideLowPerformaer)}>
            Hide Assets with {EVAL_CC_HIDE} Eval CC
          </Button>
        </Stack>
        <Grid style={{ height: 800, width: '100%' }}>
          {!syncing ? (
            <DataGrid
              density="compact"
              disableVirtualization
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              filterModel={{
                items: filt,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'evaluationNative', sort: 'desc' }],
                },
              }}
              getRowId={(row) => row.id}
              rows={currentData}
              loading={syncing}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 50, 100]}
              pagination
            />
          ) : (
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default NftAssets;
