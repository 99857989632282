import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#F9FAFB",
    },
    primary: {
      dark:'#aaaaaa',
      main: '#24262f',
      
    },
    custom:{
      mainWallet:'rgb(239 255 254)',
      mainWalletHover:'#aaaaaa',
      gain:'#1fee23',
    },
    mode:'light'
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    }
  },
  typography:{
  }
});

export default theme;